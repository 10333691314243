import React from "react";
import Helmet from "react-helmet";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <html lang="en" />
          <title>Blog</title>
          <meta
            name="description"
            content={
              "Simplify your finances with Pineapple Money - your go-to for straightforward financial guidance, making tough life decisions a breeze."
            }
          />
          <meta
            name="robots"
            content="max-image-preview:large"
          />
        </Helmet>
        <div
          className="margin-top-0"
          style={{textAlign: "center", color: "black"}}
        >
          <h1
            className="has-text-weight-bold is-size-1"
          >
            Latest Stories
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
